import GenericHead from 'components/GenericHead';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'components/Loader';
import PageBody, { ViewName } from 'views/PageBody';
import PodcastCategoryHead from './PodcastCategoryHead';
import PodcastSelect from '../PodcastSelect';
import PodcastTiles from 'components/PodcastTiles/PodcastTiles';
import PopularPodcasts from 'views/Podcast/PopularPodcasts';
import Section from 'components/Section';
import { Category, Podcast } from 'state/Podcast/types';
import { Component } from 'react';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { isEqual } from 'lodash-es';
import { PODCAST_POPULAR_CATEGORY_ID } from 'state/Podcast/constants';
import { TILE_RES } from 'components/MediaServerImage';

const PODCASTS_CHUNK_SIZE = 20;

type Props = {
  categories: Array<Category>;
  categoryId: string | number;
  categoryName: string;
  categoryPodcasts: Array<Podcast>;
  countryCode: string;
  fbAppId: string;
  fbPages: string;
  imgWidth: number;
  isAuthenticated: boolean;
  pathName: string;
  receivedFollowed: boolean;
  requestFollowed: () => void;
  siteUrl: string;
  translate: IGetTranslateFunctionResponse;
};

type State = {
  podcastsToShow: Array<Podcast>;
};

export default class PodcastCategories extends Component<Props, State> {
  static defaultProps = {
    imgWidth: TILE_RES,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      podcastsToShow: [],
    };
  }

  componentDidMount() {
    this.populatePodcasts();
    this.props.requestFollowed();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { categoryPodcasts: nextCatecategoryPodcasts } = nextProps;

    if (
      !this.props.isAuthenticated &&
      nextProps.isAuthenticated &&
      !nextProps.receivedFollowed
    ) {
      nextProps.requestFollowed();
    }

    if (
      !isEqual(this.props.categoryPodcasts, nextCatecategoryPodcasts) &&
      nextCatecategoryPodcasts.length &&
      !nextCatecategoryPodcasts.find(elem => isEqual(elem, {}))
    ) {
      this.populatePodcasts(nextProps);
    }
  }

  populatePodcasts = (props?: Props) => {
    const { categoryPodcasts } = props || this.props;
    this.setState({
      podcastsToShow: [...categoryPodcasts.slice(0, PODCASTS_CHUNK_SIZE)],
    });
  };

  loadItems = () => {
    this.setState(({ podcastsToShow }) => ({
      podcastsToShow: [
        ...this.props.categoryPodcasts.slice(
          0,
          podcastsToShow.length + PODCASTS_CHUNK_SIZE,
        ),
      ],
    }));
  };

  render() {
    const {
      categoryId,
      categoryName,
      categoryPodcasts,
      categories,
      countryCode,
      translate,
      imgWidth,
    } = this.props;

    const isWwUser = countryCode === 'WW';

    const isPopularCategory =
      String(categoryId) === PODCAST_POPULAR_CATEGORY_ID;

    const popularPodcasts = isPopularCategory ? null : (
      <PopularPodcasts length={9} />
    );

    const more = isWwUser ? popularPodcasts : null;

    const { podcastsToShow } = this.state;
    return (
      <>
        <GenericHead
          deeplink={`goto/podcast/category/${categoryId}`}
          description={translate(
            'Listen to the best {categoryName} radio shows, free and on demand, only on iHeart.',
            { categoryName },
          )}
          legacyDeeplink={`goto/podcast/category/${categoryId}`}
          ogType="website"
          title={translate('Listen to the Best Free {categoryName} Podcasts', {
            categoryName,
          })}
        />
        <PodcastCategoryHead
          keywords={translate(
            'On Demand, On-Demand, Show, Shows, Personalities, Programs, Listen, iHeartRadio, iHeart',
          )}
          title={categoryName}
        />
        <PageBody
          dataTest={ViewName.PodcastCategory}
          isWwUser={isWwUser}
          more={more}
          noAds={isWwUser}
          title={translate('Discover Top Podcasts')}
        >
          <PodcastSelect
            categories={categories}
            title={categoryName}
            value={categoryId}
          />
          <Section header={categoryName} isHidden={!podcastsToShow.length}>
            <InfiniteScroll
              hasMore={podcastsToShow.length < categoryPodcasts.length}
              loader={<Loader key="podcasts-loader" />}
              loadMore={this.loadItems}
              pageStart={0}
            >
              <PodcastTiles
                imgWidth={imgWidth}
                itemSelectedLocation="category|podcasts|tile"
                podcasts={podcastsToShow}
                tilesInRow={isWwUser && isPopularCategory ? 4 : 3}
              />
            </InfiniteScroll>
          </Section>
        </PageBody>
      </>
    );
  }
}
